.admin table {
	tr:nth-child(odd) {
		background-color: #fafafb;
	}
}

table {
	width: 100%;
	text-align: center;

	.thumb,
	.name {
		text-align: left;
	}

	.revision_history {
		text-align: left;
	}

	thead {
		th {
			text-transform: uppercase;
			font-size: 0.8em;
			font-weight: 400;
			padding: 1.2em 0.4em;
		}
	}

	&.outline {
		border-top: 1px solid #333;
		border-bottom: 1px solid #333;
	}

	tbody {
		tr {
			transition: all 0.3s ease;
			height: 4em;
		}
		// tr:nth-child(odd) {
		// 	background-color: #fafafb;
		// }

		tr:hover {
			cursor: pointer;
			background-color: #eaeded;
		}

		td {
			// padding: 0.4em;
			font-size: 0.9em;
			padding: 0.6em 0.4em;

			img {
				margin: 0 auto;
			}
		}

		td.name {
			font-size: 1em;
			font-weight: bold;
		}
	}

	a {
		text-decoration: none;
		color: inherit;

		&:hover {
			text-decoration: underline;
			color: $color-text;
		}
	}

	.timestamp {
		opacity: 0.4;
		font-size: 0.9em;
	}

	.thumb {
		width: 74px;
	}

	th span {
		margin-right: -12px;
	}

	span.asc {
		display: inline-block;
		transform: rotate(180deg);
		transform-origin: 50% 11px;
	}

	span.sorted,
	span.unsorted {
		img {
			display: inline;
		}
	}

	span.unsorted {
		opacity: 0;
	}

	span.sorted {
		opacity: 1;
	}

	.type,
	.site {
		text-transform: uppercase;
	}
}

.pagination {
	padding: 0.5em;
	display: flex;
	padding: 0.5em;
	justify-content: space-between;

	button.primary,
	button.secondary {
		padding: 0em 2em;
		font-size: 1.5em;
		padding-bottom: 0.3em;
	}
}
