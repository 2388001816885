.loginContainer {
	min-height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	img {
		width: 100%;
  		margin: 3em auto;
	}

	.loginForm {
		width: auto;
		margin: 0 auto;
		min-width: 20em;
	}

	input[type='submit'] {
		margin-top: 1em;
		text-transform: uppercase;
	}


	form {
		label {
			margin-bottom: 1em
		}

		.button {
			// margin-top: 4em
		}
	}
}

.inactive {
	opacity: 0.3;
}
.external {
	a {
		// color: $purple !important;
		&:after {
			content: ' » ';
		}
	}
}

.admin {
	position: relative;
	min-height: 100vh;
	width: 100%;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: row;
	text-align: left;

	.grid-narrow {
		max-width: none;
	}

	h1,
	h2,
	h3 {
		&:after {
			margin: 0;
		}
	}

	& > .left {
		// display: flex;
		// overflow: auto;
		// width: 175px;
		justify-content: space-between;
		display: flex;
		flex-direction: column;
		flex-basis: 175px;
		flex-grow: 0;
		flex-shrink: 1;
		// max-width: 175px;
		// position: absolute;
		background-color: $dark;
		position: relative;

		height: 100vh;
		position: sticky;
		top: 0px;

		// top: 0;
		// left: 0;
		// bottom: 0;

		&.minimized {
			width: 64px;
			flex-basis: 64px;
			.nav {
				opacity: 0.33;
			}

			.toggle {
				bottom: 64px;
				padding: 0.6em;
			}
		}

		.toggle {
			// position: sticky;
			bottom: 32px;
			height: 4em;
			width: 100%;
			border: none;
			background-color: rgba(127, 127, 127, 0.05);
			color: white !important;
			font-size: 1.2em;
		}

		.nav {
			h2 {
				margin-bottom: 0em;
				&:after {
					top: 0;
					background: url(../../images/wave_white.svg) no-repeat left top;
				}
			}

			h3 {
				&:before {
					top: 0;
					background: url(../../images/wave_white.svg) no-repeat left top;
				}
			}
			.navimg {
				padding: 0em;
				margin: 2em;
				max-width: 100%;
			}
			img {
				// margin: 2em;
				max-width: 100%;
				height: auto;
				margin-top:2em;
				display: block;
		
			}
			ul {
				margin-top: 0;
				padding-left: 0;
				list-style: none;
				padding-top: 1em;
				flex-direction: column;
				li {
					// padding-bottom: 1.6em;
					padding: 0em;
					margin-bottom: 0.4em;

					&.spacer {
						background-color: $almostwhite;
						// margin: 0em;
						height: 4px;
					}

					& > ul {
						padding-top: 0;
						padding-left: 1em;
					}

					ul > li {
						margin-bottom: 0.2em;
						a {
							padding: 0.3em 2em;
						}
					}
				}
			}
		}
		// .fixed-nav {
		// 	position: sticky;
		// 	top: 0;
		// 	margin-bottom: 2em;
		// }

		a {
			display: block;
			padding: 0.8em 2em;

			font-family: $f-basic;
			font-weight: 600;
			font-style: normal;
			color: white;
			text-transform: uppercase;
			text-decoration: none;
			font-size: 0.8em;
			border-left: 4px solid rgba(0, 0, 0, 0);
			transition: all 0.2s ease-in-out;

			&.active {
				color: $tealish;
				border-left: 4px solid $tealish;
			}

			&:hover {
				text-decoration: underline;
				opacity: 0.85;
			}



			&.logout {
				font-weight: 400;
				opacity: 0.7;
			}
		}

		.versionInfo {
			bottom: 0;
			left: 0;
			color: white;
			opacity: 0.5;
			font-size: 0.8em;
			padding: 0.5em 0em;
			text-align: center;
			background: #2b3239;
			// width: 15em;
		}
	}

	& > .right {
		min-height: 100%;
		height: 100%;
		background-color: $light;
		padding: 1em 2em;

		flex-direction: column;
		flex-grow: 1;
		flex-shrink: 1;

		display: block;
		text-align: left;

		h1 {
			&:after {
				display: none;
			}
		}
	}

	h1,
	h2,
	h3 {
		text-align: left;
	}

	/*  ------------------------------ */

	header,
	.header-bottom {
		text-align: center;

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			li {
				padding: 1em;
				// display: inline;
				a {
					color: #fff;
					font-size: 1.2em;
					font-weight: bold;
					text-decoration: none;

					// &.active {
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
	.header-bottom {
		position: sticky;
		top: 0;
		z-index: 1;
		margin-left: -2em;
		width: calc(100% + 4em);
	}
	&.unsaved {
		header {
			background-color: #939393;
		}
		nav {
			background-color: #7e7e7e;
		}
	}
	header {
		font-family: sofia-pro, sans-serif;
		text-align: left;
		background-color: $tealish;
		position: relative;
		margin-top: -1em;
		margin-left: -2em;
		padding: 3em 2em;
		width: calc(100% + 4em);

		&:before {
			content: ' ';
			background-color: rgba(31, 37, 50, 0.08);
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			position: absolute;
			pointer-events: none;
		}

		.inner {
			.top {
				.img-box {
					width: 110px;
					height: 110px;
					background-color: rgba(255, 255, 255, 0.15);
					display: inline-block;
					vertical-align: middle;
					border-radius: 4px;
					margin-top: 2em;
					margin-bottom: 2em;
					margin-right: 1em;
				}
				h1 {
					font-weight: 500;
					span.category {
						text-transform: uppercase;
						letter-spacing: 0.51px;
						color: black;
						font-size: 12px;
						margin-left: 4px;
						line-height: 14px;
						display: block;
					}

					a.extlink {
						text-transform: uppercase;
						letter-spacing: 0.51px;
						color: black;
						font-size: 12px;
						margin-left: 4px;
						line-height: 14px;
						margin-top: 10px;
						display: block;
						text-decoration: none;
						&:hover {
							text-decoration: underline;
						}
					}

					color: white;
					vertical-align: middle;
					display: inline-block;
					margin: 0;
					font-size: 44px;
					line-height: 44px;
					letter-spacing: -0.6px;
				}
			}
		}
	}

	nav {
		font-family: sofia-pro, sans-serif;
		text-align: left;
		background-color: $tealish;
		// padding-top: 1em;
		// padding-bottom: 1em;
		padding: 1em 2em;
		width: 100%;
		ul {
			li {
				font-size: 12px;
				font-weight: 500;
				letter-spacing: 0.51px;
				color: white;
				text-transform: uppercase;
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}

	/*  ------------------------------ */

	.table-row {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;

		.table-cell {
			flex-grow: 1;

			&.right-cell {
				// float: right;

				img {
					padding-left: 1em;
					vertical-align: middle;
				}
				a {
					color: #1f2532;
					text-decoration: none;
					text-transform: uppercase;
					font-size: 14px;
					font-weight: 500;
					letter-spacing: 0.51px;
					line-height: 49px;

					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-end;
				}
				.add-link {
				}
			}
		}
	}

	.places-list-header {
		padding-bottom: 1em;
		margin-bottom: 1em;
		border-bottom: 1px solid #1f2532;

		h1 {
			font-size: 43px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 52px;
			margin-top: 0;
			margin-bottom: 0;
			color: $purple;
		}

		.places-header-amount {
			i {
				color: #1f2532;
				font-size: 12px;
				font-style: italic;
				letter-spacing: 0.51px;
				line-height: 14px;
				text-transform: uppercase;
				display: block;
			}
			strong {
				color: #1f2532;

				font-size: 14px;
				font-style: italic;
				font-weight: bold;
				letter-spacing: 0.6px;
				line-height: 18px;
			}
		}
	}

	.company-form {
		p {
			// display: none;
			font-size: 0.9em;
			color: #2b3239;
		}

		section {
			padding: 2em;
			padding-bottom: 4em;
			border-bottom: 1px dashed rgba($tealish, 0.3);

			width: 100%;
			max-width: 1200px;
			// margin-bottom: 3em;

			fieldset:last-of-type {
				border: none !important;
				padding-bottom: 1em;
			}
		}
	}

	.submit-holder {
		display: flex;
		justify-content: flex-end;
		position: sticky;
		bottom: 0;

		button {
			background-color: $darkblue;
			border: none;
			outline: none;
			color: white;
			text-transform: uppercase;
			border-radius: 4em;
			padding: 1em 3em;
			cursor: pointer;
			font-family: $f-basic;
			font-weight: 700;
			font-style: normal;
			font-size: 1em;
			margin-bottom: 1em;
			margin-right: 1em;
		}
	}

	.common-list {
		padding: 0em !important;
	}

	.common-list-header {
		display: flex;
		justify-content: space-between;
		padding-bottom: 1em;
		margin-bottom: 1em;
		margin-top: 2em;
		border-bottom: 1px solid #1f2532;

		h1 {
			font-size: 43px;
			font-weight: 500;
			letter-spacing: 0;
			line-height: 52px;
			margin-top: 0;
			margin-bottom: 0;
			color: var(--accentcolor);
			line-height: 0.8;
		}

		.common-list-data {
			display: flex;
			margin-left: 2em;
			margin-right: auto;
		}

		.common-header-add {
			// margin-left: auto;
			button {
				background: none;
				border: none;
				border-radius: 0;
				box-shadow: none;
				padding: 0;
				margin: 0;
				font-size: inherit;
				font-weight: normal;
				// color: #fff;
				color: inherit;
				display: flex;
				flex-direction: row;
				align-items: center;
				text-transform: uppercase;

				img {
					margin-left: 1em;
				}
			}
		}

		.common-header-amount {
			display: flex;
			flex-direction: column;
			margin: 0em 1em;

			i {
				color: #1f2532;
				font-size: 12px;
				font-style: italic;
				letter-spacing: 0.51px;
				line-height: 14px;
				text-transform: uppercase;
				display: block;
			}
			strong {
				color: #1f2532;

				font-size: 14px;
				font-style: italic;
				font-weight: bold;
				letter-spacing: 0.6px;
				line-height: 18px;
			}
		}
	}

	.dot-map-holder {
		// border: 2px solid fuchsia;
		display: flex;
		flex-direction: column;

		svg {
			border: 2px solid $almostwhite;
			display: block;
		}
	}
}
