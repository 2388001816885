#flow {
	margin: 0 auto;
	padding-bottom: 4em;

	@include min($medium) {
		width: 80%;
		max-width: $content-width;
	}
}

.steps-wrapper {
	width: 100%;
	overflow: auto;
	-webkit-overflow-scrolling: touch;

	// @include min($medium) {
	// 	position: sticky;
	// 	top: 0;
	// 	background: #fff;
	// }
}

.steps {
	margin-bottom: 3em;
	margin-top: 1em;
	padding-top: 1em;
	justify-content: center;
	font-size: 0.9em;
	z-index: 100;
	// display: inline-flex;
	// flex-wrap: nowrap;

	&:before {
		content: ' ';
		width: 100%;
		height: 2px;
		background: $lightgray;
		position: relative;
		top: 0.75em;
	}

	li {
		cursor: pointer;
		display: none;
		@include min($medium) {
			display: block;
		}

		&:before {
			content: ' ';
			width: 1em;
			height: 1em;
			top: -1em;

			background-color: $white;
			display: block;
			border-radius: 50%;
			border: 2px solid $lightgray;
			margin: 0 auto;
			position: relative;
		}

		// &:hover,
		// &:focus,
		&.active {
			// font-weight: bold;
			color: $black;
			display: block;
			&:before {
				// background-color: $purple;
				// border-color: $purple;
				background-color: $rose;
				border-color: $rose;
			}
		}

		&.done {
			&:before {
				background-color: $tealish;
				border-color: $tealish;
			}
		}
	}
}

.signup-form {
	padding: 1em 2em;
	max-width: $form-width;
	margin: 0 auto;

	@include min($medium) {
		padding: 1em;
	}

	section {
		display: none;
		& > div {
			text-align: left;
		}

		h2,
		h3 {
			margin-left: auto;
			margin-right: auto;
			text-align: center;
			padding-bottom: 1em;
		}

		h3 {
			text-align: left;
		}

		p {
			max-width: $medium;
			margin: 1em auto;
		}

		&.active {
			display: block;
		}
	}

	.next {
		margin-top: 2em;
	}
	.back {
		// display: block;
	}

	// .split {
	// @include min($large) {
	// 	display: flex;
	// 	flex-direction: row;
	// }

	// .left {
	// 	flex-basis: 33%;
	// 	flex-shrink: 1;
	// }

	// }

	form {
		text-align: left;

		button {
			margin: 0 auto;
		}
	}

	.form-controls {
		margin-top: 2em;
		text-align: center;
	}

	.subsection {
		border-bottom: 1px solid $lightgray;
		padding-bottom: 4em;
		margin-bottom: 2em;
	}

	.pad-section {
		padding: 3em;
		padding-top: 1em;
	}

	.step-instagram {
		p {
			text-align: left;
		}
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: all 5000s ease-in-out 0s;
	// color: $dark;
}

.multi-input-wrapper {
	display: flex;
	flex-direction: column;

	@include min($medium) {
		flex-direction: row;
		.input-wrapper {
			flex-grow: 1;
			flex-shrink: 1;
			margin-left: 1em;
			&:first-child {
				margin-left: 0;
			}
		}

		&.address .input-wrapper:first-child {
			// flex-basis: 20%;
		}

		&.media > div {
			flex-basis: 33%;
		}
	}
}

.test-link {
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;

	& > label {
		flex: 1 1 auto;
	}

	& > .button {
		flex: 0 1 auto;
		margin-left: 1em;
		margin-bottom: 0.4em;
	}
}

.label {
	// opacity: 0.4;
	// text-transform: uppercase;
	color: $color-text;
	font-weight: 600;
	text-align: left;
	// letter-spacing: 0.5px;
	display: block;
	margin: 2em 0em;
	margin-bottom: 0;
}

.required.label,
.required > .label {
	// font-weight: bold;
	// opacity: 0.7;
	&:after {
		content: '*';
		display: inline;
	}
}
label {
	// color: black;
	// text-transform: uppercase;
	// font-size: 0.7em;

	// text-align: left;
	display: block;

	&.label-line {
		input,
		textarea {
			border-top: none;
			border-left: none;
			border-right: none;
		}
	}
}

.checkbox-label,
.radio-label,
.switcher {
	display: flex;
	flex-direction: row;
	// align-items: center;
	align-items: flex-start;
	margin-top: 1em;
	input {
		margin: 0 0.5em;
		width: auto;
	}

	.switch {
		margin-right: 1em;
	}

	&.disabled {
		pointer-events: none;
		opacity: 0.4;
	}
}

input,
textarea,
select {
	width: 100%;
	display: block;
	// border: 1px solid rgba(120, 120, 120, 0.2);
	border: 1px solid $gray;
	border-radius: 3px;
	box-sizing: border-box;

	padding: 1.1em 0.8em;
	outline: none;
	background: none;
	background-color: rgba(255, 255, 255, 0.95);

	color: black;

	font-size: 0.9em;
	font-family: $f-basic;

	&:hover {
		box-shadow: $shadow2;
	}

	&:focus {
		border-color: $darkblue;
		box-shadow: $shadow2;
	}

	&:invalid:focus,
	&:invalid:required {
		border-color: red;
	}

	&[disabled] {
		opacity: 0.5;
		background-color: rgba(245, 245, 245, 0.95);
	}
}

select {
	height: 3.75em;
}

textarea {
	min-height: 10em;
}
.ck-editor__editable_inline {
	min-height: calc(7em - 0.4em);
}

label.checkbox {
	& > div {
		padding-top: 0;
		// margin: 0;
		margin-top: 0.4em;
		display: flex;
		align-items: flex-start;

		span {
			margin-top: 0.2em;
			text-align: left;
		}
	}
}

input[type='radio'],
input[type='checkbox'] {
	width: 1.33em;
	height: 1.33em;
	display: inline-block;
	margin: 0 0.5em;
	margin-left: 0;
	margin-top: 0.25em;
	border: 2px solid $darkblue;
	flex-shrink: 0;
}

.radioGroup {
	display: flex;
	flex-wrap: wrap;
	text-align: left;

	.radio {
		flex-shrink: 1;
		flex-basis: 100%;
		padding-right: 1em;

		@include min($small) {
			flex-basis: 50%;
		}

		@include min($medium) {
			flex-basis: 33%;
		}

		@include min($large) {
			flex-basis: 25%;
		}
	}

	&.full-wide {
		.radio {
			flex-basis: 100%;
		}
	}

	.option-sublabel {
		opacity: 0.5;
		display: block;
	}
}

.checkboxesGroup {
	display: flex;
	flex-wrap: wrap;
	text-align: left;

	.checkbox {
		flex-shrink: 1;
		flex-basis: 100%;
		padding-right: 1em;

		@include min($small) {
			flex-basis: 50%;
		}

		@include min($medium) {
			flex-basis: 33%;
		}

		@include min($large) {
			flex-basis: 25%;
		}

		& > div {
			margin-top: 0em;
		}
	}

	&.sparse {
		.checkbox {
			@include min($small) {
				flex-basis: 100%;
			}

			@include min($medium) {
				flex-basis: 50%;
			}

			@include min($large) {
				flex-basis: 33%;
			}
		}
	}

	&.full-wide {
		.checkbox {
			flex-basis: 100%;
		}
	}

	.option-sublabel {
		opacity: 0.5;
		display: block;
	}
}

.paymentInfo {
	max-width: ($medium + 80px);
	margin: 0 auto;
	text-align: center;
	margin-bottom: 3em;

	margin-left: -2em;
	margin-right: -2em;

	@include min($large) {
		margin: 0 auto;
	}

	th,
	td {
		padding: 0em;
	}

	tr {
		height: auto;
	}

	th,
	td,
	tr {
		background: transparent !important;
		text-align: left;
	}

	.panel {
		background: $paymentbox;
		padding: 2em;
		margin-top: 2em;
		margin-bottom: 1em;
		// word-break: break-all;

		tr,
		td,
		th {
			display: block;
		}
		tr {
			margin-bottom: 0.5em;
		}

		@include min($small) {
			padding: 3em;
			margin-top: 2em;
			margin-bottom: 1em;
			padding-top: 1em;

			tr {
				display: table-row;
				margin: 0;
			}

			td,
			th {
				display: table-cell;
			}

			table {
				margin-left: 2em;
			}
		}

		h3 {
			padding-bottom: 0;
		}
	}

	.payment-providers {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-content: center;
		justify-content: center;
		margin-top:2em;
		gap:16px;
		a {
			display: flex;
			flex:  1 100%;
			/* padding: 0.5em 1em; */
			align-items: center;
			flex-direction: row;
			align-content: center;
			justify-content: center;
			max-width: max(22%, 10em);
			transition: all 0.2s ease-in-out;
			border-radius: 8px;

			&:hover {
				box-shadow: 0px 4px 20px rgba(38, 43, 113, 0.2);
			}

			img {
				flex: 1 1 auto;
				display: block;
				margin: 0.4em 1em;
			}
		}
	}

	form {
		text-align: center;
	}
}

.video-preview {
	max-width: 100%;
	width: auto;
	display: flex;
}

/*pic selector*/
.picture-selector {
	border: 1px dashed gray;
	border-radius: 4px;
	width: 340px;
	height: 340px;
	text-align: center;
	margin-bottom: 6em;
	// overflow: hidden;

	width: 100%;
	height: auto;
	padding-bottom: 100%;

	position: relative;

	// &.share {
	// 	width: 340px;
	// 	height: 180px;
	// }
	// &.portrait {
	// 	width: 340px;
	// 	height: 425px;
	// }
	&.share {
		padding-bottom: 54%;
	}
	&.portrait {
		padding-bottom: 125%;
	}
	&.contain {
		width: auto;

		.picture {
			background-size: contain;
		}
	}

	&.picture-selected {
		img {
			display: none;
		}
		&:hover {
			img {
				display: inline-block;
			}
		}
	}

	&.isUploading {
		pointer-events: none;
	}
	.error & {
		border-color: red;
	}

	input {
		display: none;
	}

	button {
		margin: 0.5em auto !important;
	}

	.actions {
		position: absolute;
		width: 100%;
		top: 100%;
		flex: 1 1 auto;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;

		button.button {
			padding: 0.6em 2em;
			// margin: 0.5em 0.2em !important;
			margin: 0.4em 0.2em 0.1em 0.2em !important;
		}
	}

	// .picture-inner {
	// 	width: 100%;
	// 	height: 100%;
	// 	position: absolute;
	.picture {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;

		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		// position: relative;
		cursor: pointer;
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -25px;
			margin-top: -25px;
		}
	}
	// }
}

fieldset {
	border: none;
	outline: none;
	padding: 1em;
	// margin-top: 24px;
	padding-bottom: 48px;
	border-bottom: 1px solid rgba(31, 37, 49, 0.2);

	.required > .label {
		// font-weight: bold;
		// opacity: 0.7;
		&:after {
			content: '*';
			display: inline;
		}
	}

	legend,
	h2 {
		color: #1f2532;
		// font-family: "Sofia Pro";
		font-size: 28px;
		font-weight: 500;
		letter-spacing: -0.2px;
		line-height: 32px;
	}
}

.drag-and-drop {
	background-color: $almostwhite;
	// border: 1px solid $gray;
	border-radius: 4px;
	margin-top: 0.5em;

	.add {
		// background-color: $almostwhite;
		// border-bottom: 1px solid $gray;
		display: flex;
		flex: 0 1 auto;
		justify-content: flex-start;
		align-items: center;
		padding: 1em 0em;
	}

	ul {
		margin-left: -2.8em;
	}

	.module {
		flex-grow: 1;
		& > .label {
			opacity: 0.5;
		}
	}

	.drag-and-drop ul {
		margin-left: 0;
	}

	&.fixed ul {
		margin-left: 0em;

		.multi-input-wrapper {
			width: 100%;
		}

		.remove {
			margin: 0em 0em;
			padding: 0.25em 1em;
			margin-top: 1em;
		}
	}

	.droppable-area {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;

		li {
			width: 100%;
			display: flex;
			padding: 1.5em 0em;
			// align-items: flex-end;
			align-items: center;
			justify-content: stretch;
			border-bottom: 1px solid rgba(127, 127, 127, 0.1);

			& > .drag-bar {
				display: flex;
				align-items: flex-end;
				margin: 1em;
			}

			button:not(.ck-button),
			.remove {
				margin: 0.25em 1em;
				padding: 0.25em 1em;
				cursor: pointer;
				&:hover {
					opacity: 0.8;
				}
			}

			.label {
				// margin-top: 0em;
			}

			.dnd-actions {
				flex: 1 1 auto;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: flex-end;
				align-items: center;
			}
		}
	}

	.drag-and-drop-tools {
		padding: 1em 2em;

		button.secondary {
			margin: 0.2em;
		}
	}
}

.page-section-editor {
	.multi-input-wrapper {
		flex: 1 1 auto;
	}

	&.drag-and-drop {
		margin-top: 0em;

		.dnd-actions,
		.drag-bar {
			opacity: 0;
		}

		ul {
			margin-left: 0;
		}

		li:hover {
			// background-color: $almostwhite;
			.dnd-actions,
			.drag-bar {
				opacity: 1;
			}
		}

		.dnd-actions {
			min-width: 11em;
			.remove {
				margin: 0em;
			}
		}

		.droppable-area li {
			padding: 1em 0em;
		}
	}

	.module {
		flex: 1 1 auto;

		& > .label {
			opacity: 0.33;
			text-transform: uppercase;
			font-size: 0.8em;
			margin-bottom: 1em;
		}

		& > div {
			margin-left: 2em;
		}
	}

	.module-video,
	.module-image,
	.module-carousel,
	.module-faq {
		flex: 1 1 auto;

		.multi-input-wrapper {
			flex: 1 1 auto;
			flex-wrap: wrap;
			flex-direction: column;

			.input-wrapper {
				margin-left: 0em !important;
				margin-bottom: 1em;
			}
		}
	}
}
