.cta-wrapper {
	// position: sticky;
	top: 0.2em;
	z-index: 101;
	text-align: center;
	margin-top: -14em;
	margin-bottom: 5em;

	.cta {
		margin: 0;

		@include max($small) {
			font-size: 1.4em;
			width: 100%;
			border-radius: 0;
		}
	}

	@include max($small) {
		top: 0;
	}
}

.frontpage {
	width: 100%;
	section {
		padding: 3em 2em;
		background: #fff;
		overflow-x: hidden;
		@include min($medium) {
			padding: 4em 5em;
		}
		@include min($large) {
			padding: 4em 8em;
		}
	}

	.hero {
		background: url(../../images/tausta.jpg) repeat-x center center;
		background-size: cover;
		color: $white;
		padding-bottom: 15em;
		// word-break: break-all;
		hyphens: auto;
		h1 {
			color: $white;
			font-size: 2.1em;
			max-width: $large;
			margin: 1em auto;
			font-weight: bold;
			line-height: 1.2em;

			@include min($medium) {
				font-size: 3.3em;
			}

			&:after {
				background: url(../../images/wave_white.svg) no-repeat center center;
			}

			p {
				line-height: 1.4em;
				font-size: 0.75em;
				margin-top: 0.2em;
			}
		}
		a {
			margin-top: 1em;
		}
	}

	h2 {
		color: $darkblue;
		font-size: 1.9em;
		line-height: 1em;
		text-align: center;

		@include min($medium) {
			font-size: 2.9em;
		}
	}

	h2,
	h3 {
		&:after {
			display: none;
		}
	}

	.box {
		margin-top: -8em;
		text-align: left;
		p {
			font-size: 1.2em;
			line-height: 1.5;
		}

		strong {
			font-weight: bold;
			color: $darkblue;
		}
	}

	.highlights {
		display: flex;
		flex-direction: row;
		text-align: center;

		li {
			padding: 0;
		}

		img {
			max-width: 120%;
			margin: 0 auto;
			display: block;
			margin-right: -20%;
			margin-bottom: -20%;

			@include min($medium) {
				width: 25em;
				float: right;
				margin-top: -2em;
				margin-right: -10em;
				margin-bottom: -7em;
			}
			@include min($large) {
				margin-bottom: -10em;
			}
		}

		&.list {
			li {
				flex-basis: 100%;
				margin-top: 0 !important;
				text-align: left;
			}

			h3 {
				text-align: left;
				color: $darkblue;

				font-size: 1.6em;
				font-weight: bold;
				letter-spacing: -0.37px;
				line-height: 1.4em;
			}
		}

		// li {
		// 	flex-basis: 33.3333%;

		// 	&:nth-child(2n + 1) {
		// 		margin-top: 3.5em;
		// 	}
		// }

		// p {
		// 	padding: 0 2em;
		// }

		// img {
		// 	width: 10em;
		// 	height: 10em;
		// 	border-radius: 50%;
		// 	background-color: $lightgray;
		// 	border: none;
		// 	margin: 0 auto;
		// 	margin-bottom: -2.5em;
		// }
	}

	.faq {
		padding-top: 0em;

		ul {
			max-width: 680px;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			// border: 1px solid $gray;
			text-align: left;

			li {
				// padding: 1em 2em;
				padding: 0em;
				border-top: 1px solid $color-text;

				&:first-child {
					border-top: none;
				}

				&.active .faq-a {
					display: block;
				}

				&.active .faq-q {
					margin-bottom: 0;
					&:after {
						transform: rotateZ(180deg);
					}
				}
			}

			.faq-a,
			.faq-q {
				padding: 1rem 2rem;
				padding-left: 0;
			}

			.faq-q {
				font-size: 1.2em;
				font-weight: bold;
				padding-right: 3rem;
				position: relative;
				cursor: pointer;

				&:after {
					background-image: url('../../images/dropdown_arrow.svg');
					background-position: right center;
					background-repeat: no-repeat;
					background-size: 100%;

					content: ' ';
					width: 0.7em;
					height: 0.7em;
					position: absolute;
					right: 1em;
					top: 1.5em;
				}
			}

			.faq-a {
				display: none;
				margin-top: 0;
			}
		}
	}

	.contact-us {
		text-align: center;

		a {
			color: $color-text;
		}
	}
}
