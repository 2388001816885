$lightgray: #e7e7e7;
$gray: #adacac;
$dark: #1f2532;
$black: #151515;
$white: #fff;
$purple: #c800a1;

$color-text: #050504;
$light: #ffffff;
$blue: #0091ff;

$shadow1: 0em 0.5em 0.75em rgba(0, 0, 100, 0.075);
$shadow2: 0 9px 4px -6px rgba(0, 0, 0, 0.07);

$almostwhite: rgba(127, 127, 127, 0.05);


$tealish: #44C8CA;
// $darkblue: #262b71;
$darkblue: #034596;
$rose: #FFA8A5;
$cream: #FFEEE0;

$paymentbox: $cream;

$f-basic: sofia-pro, sans-serif;

$form-width: 1024px;
$content-width: 1200px;
$list-width: 1400px;

// Screen size breakpoints (for min-width queries)
$small: 480px;
$medium: 641px;
$large: 880px;
$xlarge: 1200px;

// Screen size breakpoints (for max-width queries).
$small-max: $medium - 1px;
$medium-max: $large - 1px;
$large-max: $xlarge - 1px;

@import 'mixins';
@import 'reacttable';
@import 'toastify';

* {
	box-sizing: border-box;
}
a {
	cursor: pointer;
}

body,
html {
	min-height: 100%;
	height: 100%;
	font-family: $f-basic;
	font-weight: 400;
	font-style: normal;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	scroll-behavior: smooth;
}

main,
#root {
	width: 100%;
	height: 100%;
	min-height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	color: $color-text;
}

main {
	flex-grow: 1;
	justify-content: center;
}

main.osallistu {
	justify-content: flex-start;
}

#root {
	height: auto;
}

header,
footer {
	padding: 2em;
}

header {
	padding: 0em 1em;
	top: 0;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	flex-direction: column;
	z-index: 100;

	& > a {
		padding: 1em 0em;
		
	}

	img {
		width: 15em;
	}

	ul {
		justify-content: center;

		li {
			padding: 1em;

			a {
				font-weight: bold;
				text-transform: uppercase;
				color:$color-text;
			}
		}
	}

	.button {
		display: inline;
	}

	@include min($medium) {
		padding: 0.5em 2em;
		flex-direction: row;
		justify-content: space-between;
		flex-direction: row;
		// position: sticky;
		background-color: $white;
		img {
			width: 10em;
			height: auto;
		}
	}
}

footer {
	background: $tealish url(../../images/footer-graphic.svg) no-repeat center
		bottom;
	margin-top: 3em;
	padding: 5em 4em 8em 4em;
	position: relative;

	&:before {
		content: ' ';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 8px;
		background: url(../../images/border.svg) repeat-x center bottom;
		background-size: contain;
	}

	ul {
		margin-top: 2em;
		justify-content: center;
	}

	a {
		color: $white;
	}
}

header,
footer,
main {
	width: 100%;
	text-align: center;
}

.Toastify {
	position: absolute;
}

img {
	display: block;
}

h1 {
	font-family: $f-basic;
	font-size: 2.5em;
	font-weight: bold;
	letter-spacing: 0;
	// line-height: 1.6;
	// margin-top: 0;
	// margin-bottom: 0;
	color: $color-text;

	@include min($medium) {
		font-size: 3em;
	}
}
h2 {
	font-family: $f-basic;
	font-weight: 700;
	font-style: normal;
	color: $color-text;
	font-size: 2em;

	@include min($medium) {
		font-size: 2.3em;
	}
}

h1,
h2 {
	// font-size: 2em;

	&:after {
		content: '';
		display: block;
		width: 192px;
		height: 6px;
		/* margin-top: 3.5em; */
		background: url(https://100syyta.fi/resources/images/wave.svg) no-repeat
			left top;
		margin: 0 auto;
		top: 0.5em;
		position: relative;
	}
}

h3 {
	text-align: center;
	// margin: 0 auto;
	margin-left: auto;
	margin-right: auto;
}

ul {
	list-style: none;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	li {
		padding: 1em;
	}
}

.max-width {
	max-width: $content-width;
	margin: 0 auto !important;
}

div.container {
	position: relative;
	min-height: 100%;
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: row;
}

// .row {
// 	display: flex;
// 	flex-direction: row;
// 	.col {
// 		padding-right: 1em;
// 		flex: 1 0 0;
// 	}
// }

// .row-header .col {
// 	font-weight: bold;
// 	padding-bottom: 1em;
// }

// img.thumb {
// 	max-width: 200px;
// 	height: auto;
// }

a {
	text-decoration: none;
	&:focus,
	&:active,
	&:hover {
		text-decoration: underline;
	}
}

.button,
button,
input.button {
	background-color: $tealish;
	color: white;

	font-weight: bold;
	border: none;
	outline: none;
	border-radius: 20px;
	// padding: 1em 2em;
	// padding: 0.6em 3em;
	padding: 0.5em 3em 0.7em 3em;
	cursor: pointer;
	max-width: 20em;
	margin: 0.2em;
	display: inline-block;
	font-size: 1em;
	font-family: $f-basic;

	transition: all 0.22s ease;
}

.button.primary,
button.primary {
	background-color: $rose;
	color: $darkblue;
	font-weight: bold;
	font-size: 1.5em;
	border-radius: 1.5em;
}

button.secondary,
.button.secondary {
	padding: 0.5em 2em;
	background-color: $cream;
	margin: 0em;
	// color: $color-text;
	color: $dark;
	font-weight: normal;
}

button,
.button {
	&:hover {
		opacity: 0.95;
		box-shadow: 0.1em 0.3em 0.8em -0.2em rgba(0, 0, 0, 0.1);
	}
}

.errorFrame {
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-wrap: wrap;
	flex-direction: column;
}
.toggle-errors {
	.errors {
		text-align: left;

		p {
			margin: 0.33em 0em;
		}
	}
}

.error {
	// border: 1px solid red;
	color: red;
	input {
		border-color: red !important;
	}
}

.table {
	display: table;
	width: 100%;
	text-align: left;
	.row {
		display: table-row;
		.cell,
		.th {
			display: table-cell;
			vertical-align: middle;
			img {
				max-width: 50px;
				height: auto;
			}
		}
		.th {
			font-weight: bold;
			padding-bottom: 1em;
		}
	}
}

.map {
	background: #eee;
	flex-grow: 1;
	margin-top: 1em;
	border: 1px solid $gray;
	border-radius: 4px;

	.maps-embed {
		width: 100%;
		height: 100%;
		display: block;
		min-height: 24em;
		border: 0;
	}
}

.notesandhistory {
	// margin-top: 1em !important;
	// padding-bottom: 2em !important;

	background: $almostwhite;

	.row {
		padding: 2em;
	}

	.flex {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.notelist,
	.revlist {
		// padding: 2em;

		overflow: auto;
		max-height: 12em;
	}
	.revlist {
		background-color: transparent;
		// padding: 2em;
		max-height: 21em;
		padding-right: 0em;
	}

	.revisions {
		// opacity: 0.4;

		text-align: left;
	}

	.togglenotes {
		// padding: 2em;
		background: $almostwhite;
		cursor: pointer;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		img {
			margin-right: 1em;
		}
		&:hover {
			text-decoration: underline;
		}
	}
	.addnote {
		button {
			display: block;
			margin-left: auto;
		}
	}
	.note {
		font-size: 0.9em;
		margin-bottom: 1em;

		p {
			margin-bottom: 0;
		}

		.user {
			opacity: 0.5;
			font-size: 0.9em;
		}
	}
}

input[name='ig_account'] {
	&:before {
		content: '@';
	}
}

.ig-feed {
	overflow: auto;
	max-width: 1024px;

	.ig-wrap {
		display: flex;
		flex-direction: row;
		// padding: 1em 0em;
	}
	a {
		display: block;
	}

	p {
		margin: 1em 0.2em 0.5em 0.2em;
		font-size: 0.8em !important;
	}

	.ig-img {
		margin: 0 0.2em;
		margin-bottom: 1em;

		img {
			// max-width: 200px;
			height: 320px;
			// min-width: 320px;
			display: block;
			// max-width: 100%;
		}

		.actions button {
			margin: 0.2em auto;
			display: block;
		}
	}
}

/* LOADER */

.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;

	&.dark {
		div {
			background-color: #1f2532;
		}
	}

	div {
		position: absolute;
		top: 33px;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background: #fff;
		animation-timing-function: cubic-bezier(0, 1, 1, 0);

		&:nth-child(1) {
			left: 8px;
			animation: lds-ellipsis1 0.6s infinite;
		}
		&:nth-child(2) {
			left: 8px;
			animation: lds-ellipsis2 0.6s infinite;
		}
		&:nth-child(3) {
			left: 32px;
			animation: lds-ellipsis2 0.6s infinite;
		}
		&:nth-child(4) {
			left: 56px;
			animation: lds-ellipsis3 0.6s infinite;
		}
	}
}
@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(24px, 0);
	}
}

@import 'form';
@import 'frontpage';
@import 'company';
@import 'contentpage';
@import 'admin';

.grid-narrow {
	max-width: $medium;
	margin: 0 auto;
}
.grid-front {
	max-width: $form-width;
	margin: 0 auto;
}

.grid-2 {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;

	@include min($medium) {
		flex-direction: row;
		& > * {
			flex-basis: calc(50% - 1em);
			margin: 0em 0.5em;
		}
	}
}

.iframe-wrapper {
	position: relative;
	overflow: hidden;
	padding-top: 56.25%;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}
