.Toastify__toast--success {
	background: #0091ff;
}
.Toastify__toast {
	border-radius: 6px;
	padding: 1em;
}

.modal {
	position: fixed;
	// background-color: rgba(0, 0, 0, 0.75);
	background-color: rgba(250, 250, 250, 0.75);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;

	display: none;

	&.active {
		display: flex;
	}

	.modal-container {
		position: relative;
		background: #fff;
		color: $color-text;
		font-size: 1.2em;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		max-width: ($medium + 80px);
		padding: 4em;

		box-shadow: 0 10px 13px rgba(37, 43, 112, 0.12);

		&:after {
			content: ' ';
			width: 1.8em;
			height: 1.8em;
			background: url(../../images/close.svg) no-repeat center center;
			background-size: contain;
			top: 0.5em;
			right: 0.5em;
			position: absolute;
		}
	}

	.gmb-modal {
		&:after {
			display: none;
		}
		p {
			text-align: left;
			margin-bottom: 2em;
		}
		button,
		a {
			max-width: 100%;
			margin: 1em;
		}
	}
}
