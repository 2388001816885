.contentpage {
	max-width: $medium;
	text-align: left;
	padding: 0em 2em;
	hyphens: auto;

	ol {
		margin: 0;
		padding: 0;
		margin-top: 2.5em;
	}

	h1 {
		&:after {
			display: none;
		}
	}
}
