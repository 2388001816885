section.addnewplace {
	margin: 0 auto;
	min-width: 30%;

	h2 {
		margin-bottom: 2em;
	}

	.results {
		box-shadow: 0 10px 10px -5px rgba(31, 37, 50, 0.15);
		// padding: 1em;
		list-style: none;
		overflow: auto;
		flex-direction: column;
		text-align: left;

		.lds-ellipsis {
			margin: 0 auto;
		}

		.create {
			cursor: pointer;
		}

		li {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 1em;
			transition: all 0.22s ease;

			&:hover {
				// background-color: rgba($paymentbox, 0.2);
				background: linear-gradient(
					175deg,
					rgba($paymentbox, 0.2),
					rgba(255, 255, 255, 1)
				);
			}

			.name {
				padding-right: 1em;
			}
			.links {
				min-width: 10.5em;

				.button {
					font-size: 0.85em;
					font-weight: bold;
				}
			}
		}

		.manual {
			// padding: 0;
			// justify-content: center;
			background-color: #f5f4fa;
			font-weight: bold;

			a {
				// padding: 2em;
			}
		}

		.gplace {
			.name {
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.name:before {
				content: ' ';
				background: url(../../images/google.svg) no-repeat center center;
				background-size: contain;
				display: inline-block;
				width: 1em;
				height: 1em;
				margin-right: 1em;
			}

			.check {
				margin: 1em;
			}
		}
	}

	.wrapper {
		padding: 0em 1em;
	}
}

.confirm-dialog {
	text-align: center;
	margin-bottom: 4em;
}

.summary {
	font-size: 0.9em;
	padding: 1em 0em;
	background: rgba($paymentbox, 0.2);
	margin: 2em -2em;

	h3 {
		margin: 1em !important;
		padding-bottom: 0em !important;
		margin-bottom: 0em;

		@include min($medium) {
			margin: 2em !important;
		}
	}

	table {
		text-align: left;
		padding: 2em 2em;
		padding-top: 0em;
		margin-bottom: 1em;
		border-bottom: 1px solid $white;

		tr {
			height: auto;
		}

		th,
		td {
			padding: 0em;
			font-size: 1em;
		}

		th {
			opacity: 0.3;
			width: 10em;
			padding-right: 1em;
		}

		@include min($medium) {
			padding: 2em 4em;
			padding-top: 0em;

			th {
				width: 20em;
			}
		}
	}
}
